<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end mb-0 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-tabs
      v-model="state.currentTabIndex"
      active-nav-item-class="font-weight-bold text-uppercase text-success"
    >
      <b-tab>
        <template #title>
          <i :class="iconState('ri-phone', state.currentTabIndex === 0)" /> Pending
        </template>
        <PendingCallRequests
          :current-tab-index="state.currentTabIndex"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <i :class="iconState('ri-time', state.currentTabIndex === 1)" /> Histories
        </template>
        <CallRequestHistories
          :current-tab-index="state.currentTabIndex"
        />
      </b-tab>

    </b-tabs>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'

import PendingCallRequests from '@/views/user/call-requests/Pending'
import CallRequestHistories from '@/views/user/call-requests/Histories'

export default {

  name: 'UserRequests',

  middleware: ['auth', 'user'],

  metaInfo: () => ({
    title: 'Call Requests'
  }),

  components: {
    PendingCallRequests,
    CallRequestHistories
  },

  mixins: [
    formatter,
    misc
  ],

  data () {
    return {
      state: {
        currentTabIndex: 0
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {
  }
}
</script>
